@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

html {
  min-height: 100%;
  min-width: 100%;
}

body {
  min-height: 100%;
  min-width: 100%;
  background-color: #FAFAFA;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-family: 'roboto', sans-serif;
  padding: 0;
  margin: 0;
}

body #root {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=bodystyle.css.map */
:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

header {
  height: 58px;
  width: 100%;
  position: fixed;
  text-align: center;
  z-index: 2;
  font-family: 'Spartan', sans-serif;
  font-weight: 700;
  background-color: #FFFFFF;
  border-bottom: 1px solid #e0e0e0;
}

.left-icons {
  height: 58px;
  float: left;
}

.left-icons .logo {
  height: 35px;
  width: auto;
  float: left;
  margin: 12.77778px auto auto 17.5px;
}

.left-icons .logo .logo-img {
  width: auto;
  height: 100%;
  float: left;
}

.left-icons .logo .logo-font {
  width: auto;
  height: 73%;
  float: left;
  margin: 3px auto auto 2px;
}

.left-icons .logo:hover {
  transition: 400ms;
  height: 37px;
  margin: 12.10526px auto auto 16.66667px;
}

.search-bar {
  height: 60%;
  width: clamp(200px, 50%, 700px);
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 3;
  margin-top: 11px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 2px 2px #eeeeee;
  border-radius: 10px;
}

.search-bar .search-bar-form {
  width: 100%;
  height: 100%;
}

.search-bar .search {
  height: calc(100% - 10px);
  width: calc(100% - 60px);
  float: left;
  margin: 5px auto auto 5px;
  color: #2F3033;
  background: #FFFFFF;
  border: none;
  border-radius: 5px;
}

.search-bar .search:focus {
  outline: none;
}

.search-bar .search-btn {
  height: 100%;
  width: auto;
  float: right;
  margin-right: 10px;
  background: transparent;
  border: 1px grey;
  color: #2F3033;
}

.infos-right {
  float: right;
  transition: 200ms;
}

.infos-right:hover {
  -webkit-filter: brightness(0.96);
          filter: brightness(0.96);
}

.infos-right .exploreButton {
  margin: 14.41667px 19.22222px auto auto;
  display: inline-block;
}

.infos-right .exploreButton .explore-icon {
  width: 29.16667px;
  height: 29.16667px;
}

.infos-right .profile {
  height: 35px;
  display: block;
  margin: 11.5px 20px auto auto;
  text-decoration: none;
  color: #2F3033;
  box-shadow: 1px 1px 2px 2px #eeeeee;
  border-radius: 11.66667px;
  background-color: #FAFAFA;
  float: right;
}

.infos-right .profile p {
  max-width: 60px;
  overflow: hidden;
  font-size: 18px;
  float: left;
  margin: 10.5px 0 auto 8px;
}

.infos-right .profile .header-profile-picture {
  height: 35px;
  width: 35px;
  float: left;
  border-radius: 14px;
}

.infos-right .profile .caret {
  width: 20px;
  height: 20px;
  margin: 9.5px 10px auto 5px;
}

.infos-right .Sign-in {
  height: 40px;
  padding: 0 12px 0 12px;
  border-radius: 5px;
  text-decoration: none;
  color: #FFFFFF;
  background-color: #00C06E;
  margin: calc(calc(58px - 40px) / 2) 12px auto auto;
  float: right;
}

.infos-right .Sign-in h3 {
  font-size: 18px;
  display: block;
  margin-top: 12px;
}

.infos-right .Sign-in:hover {
  background-color: #02a660;
}

.dropdown {
  width: 220px;
  position: absolute;
  top: 53px;
  right: 12px;
  padding: 1rem;
  z-index: 10;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 2px 2px #eeeeee;
  border-radius: 8px;
  opacity: 0.98;
}

.menu-item {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: background 500ms;
  text-decoration: none;
  color: #2F3033;
  padding: 0.2rem;
}

.menu-item:hover {
  background-color: #FAFAFA;
}

.menu-item .icon-right {
  margin-left: auto;
}

.menu-item .icon-left {
  float: left;
}

.menu-item .dropdown-content {
  margin: 10px auto auto 10px;
}

.menu-item .svg-icon {
  width: 20px;
  height: 20px;
  margin: 4px 4px auto auto;
  color: #656565;
}

/*
.nav-sidebar{
    height: 100%;
    
    margin-top: $header-height;
    position: fixed;
    float: left;
    display: block;
    
    transition: 700ms;

    z-index: 1;
    background-color: $brighter-standard-color;
    border-right: 1px solid $border-color;
}

.sidebar-active{
    width: $side-bar-width;


    .new-article{
        height: 80px;

        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $border-color;

    }

    .new-article-btn{
        width: 80%;
        height: 50%;

        display: flex;
        justify-content: left;
        align-items: center;

        text-decoration: none;

        background: $accent-color;
        border-radius: 8px;
        color: $accent-text-color;

        transition: 500ms;

        .icon{
            width: 25px;
            height: 25px;
            margin-left: 10px;
        }

        &:hover{
            background-color: $accent-hover-color;
        }

        h3{
            margin: 12px auto auto 6px;
        }
    }

    .routes{
        border-bottom: 1px solid $border-color;

        
        $btns-margin: 10px;
        .route-btn{
            &:first-child{
                margin-top: $btns-margin;
            }
            &:last-child{
                margin-bottom: $btns-margin;
            }
            &:hover{
                transition: 1000ms;
                background: $button-clicked-hover-color;
            }
            h3{
                
                margin: 17px auto auto 10px;
            }
            height: 50px;
            width: 100%;

            display: flex;
            align-items: center;

            text-decoration: none;
            color: $text-color;

        }

        .btn-icon{
            height: 22px;
            width: 22px;

            float: left;
            margin-left: ((($side-bar-width * 0.2) / 2) + 10px);
        }
    }



    
}*/
/*# sourceMappingURL=nav_side_bar.css.map */
@charset "UTF-8";
:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

/*spacing between thumbnail and infos*/
.article {
  height: 145px;
  width: 100%;
  display: inline-block;
  font-family: 'Roboto', 'sans-serif';
  text-decoration: none;
  margin-top: 15px;
  border-radius: 10px;
  color: #000;
}

.article .thumbnail {
  height: 145px;
  width: 241.66671px;
  border-radius: 10px;
  float: left;
}

.article .thumbnail:hover {
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
}

.article .infos {
  width: calc(100% - 145px * 1.6667);
  width: calc(100% - var(--thumbnail-width));
  height: 145px;
  display: block;
  /*align-items: center;*/
  margin: auto auto auto auto;
  font-size: 14px;
  float: right;
}

.article .infos .infos-centered {
  height: auto;
  width: 100%;
  overflow: hidden;
}

.article .infos .category {
  margin: 5px 7.5px auto 0;
  font-size: 14px;
  color: #00C06E;
  text-decoration: none;
  float: left;
  font-weight: 600;
}

.article .infos .category:first-child::before {
  content: none;
}

.article .infos .category:before {
  margin-right: 7.5px;
  content: "•";
}

.article .infos .category:first-of-type {
  margin-left: 15px;
}

.article .infos .category:hover {
  color: #02a660;
}

.article .infos .heading {
  max-width: 95%;
  max-height: 25px;
  overflow: hidden;
  font-size: 21px;
  margin: 7.5px auto auto 15px;
}

.article .infos .article-info {
  height: 28px;
  margin: 5px auto auto 15px;
  font-weight: 400;
  font-size: 14.73684px;
  color: #656565;
}

.article .infos .profil-picture {
  width: 28px;
  height: 28px;
  float: left;
  margin: 0 auto auto 0;
  border-radius: 14px;
}

.article .infos .username, .article .infos .time, .article .infos .impressions {
  margin: 6.63158px auto auto 7.5px;
  display: inline-block;
  color: #656565;
  float: left;
}

.article .infos .time {
  max-width: 120px;
  overflow: hidden;
}

.article .infos .username::after {
  margin-left: 10px;
  content: "•";
}

.article .infos .article-preview {
  width: 95%;
  max-height: 52.5px;
  overflow: hidden;
  margin: 5px auto auto 15px;
  font-size: 15px;
  color: #656565;
}
/*# sourceMappingURL=horizontalArticle.css.map */
:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

.space {
  width: 100%;
  float: right;
  display: inline-block;
  margin-top: 58px;
}

.content {
  max-width: 1100px;
  width: 90%;
  margin: auto;
  color: #2F3033;
}

@media only screen and (max-width: 750px) {
  .content {
    width: 95%;
  }
}

.editorJS {
  margin-top: 58px;
}
/*# sourceMappingURL=article-space.css.map */
@charset "UTF-8";
:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

.heading {
  font-weight: 600;
  font-size: 18px;
  margin: 20px auto auto 0px;
}

.Categories {
  height: 83px;
  margin-top: 5px;
  overflow: hidden;
}

.Categories .Category-Button {
  display: inline-block;
  height: auto;
  padding: 6px 25px;
  text-decoration: none;
  color: #2F3033;
  margin: 8px 8px auto 2px;
  background: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0 0 4px 1px #dedede;
}

.Categories .Category-Button p {
  margin: 0;
  font-weight: 500;
}

.Categories .Category-Button:hover {
  box-shadow: 2px 2px 5px 1px #dedede;
}

/*
==============
== Articles ==
============== */
.content-flex {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  flex: 1 1;
}

.articleView:nth-child(5) {
  display: none;
}

.articleView {
  width: 100%;
  height: auto;
  margin: 0;
  border-radius: 10px;
  text-decoration: none;
  background-color: #FFFFFF;
  box-shadow: 0 0 8px 0 #dedede;
}

.articleView:hover {
  box-shadow: 1px 1px 5px 1px #dedede;
}

.thumbnail {
  width: 100%;
  border-radius: 10px 10px 0 0;
  /*:hover{filter: brightness(0.85);}*/
}

/* Category in Article */
.article-categories {
  width: 100%;
  height: 20px;
}

.article-categories .category {
  margin: 5px 6px auto 0;
  font-size: 13px;
  color: #00C06E;
  text-decoration: none;
  float: left;
  font-weight: 500;
}

.article-categories .category:first-child::before {
  content: none;
}

.article-categories .category:before {
  margin-right: 6px;
  content: "•";
}

.article-categories .category:first-of-type {
  margin-left: 12px;
}

.article-categories .category:hover {
  color: #02a660;
}

.title {
  width: 90%;
  max-height: calc(3 * 15px + 12px);
  font-size: 16px;
  color: #2F3033;
  margin: 5px auto auto 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infos {
  height: 25px;
  width: auto;
  margin-top: 12px;
  font-weight: 400;
  font-size: 13.15789px;
}

.infos .pb {
  width: 25px;
  height: 25px;
  float: left;
  border-radius: 12.5px;
  margin: 0 auto auto 12px;
}

.infos .username {
  display: inline-block;
  margin: 2.75px auto auto 10px;
}

.infos .username:after {
  margin-left: 10px;
  content: "•";
}

.infos .username, .infos .time {
  float: left;
  text-decoration: none;
  color: #2F3033;
  font-size: 14px;
}

.infos .time {
  margin-left: 5px;
  margin: 2.75px auto auto 10px;
}

.placeholder {
  width: 100%;
  height: 15px;
}

.preview {
  display: none;
}
/*# sourceMappingURL=explore.css.map */
:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

.root {
  font-family: 'roboto', sans-serif;
}

.write-page {
  width: 100%;
  height: 100%;
  background-color: #FAFAFA;
}

.publish-btn {
  position: absolute;
  right: 20px;
  top: 68px;
  height: 40px;
  width: 120px;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  text-decoration: none;
  background-color: black;
  color: white;
  border: 1px solid #FAFAFA;
  border-radius: 20px;
}

.publish-btn:hover {
  border: 1px;
}

.heading-form {
  position: relative;
  display: block;
  font-size: 40px;
  width: 655px;
  max-width: 100%;
  margin: 20px auto 10px auto;
  border: 0px solid;
  background: transparent;
  outline: none;
}

.publish-window {
  width: 600px;
  height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  background-color: #FFFFFF;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  border-radius: 10px;
  font-family: 'roboto', sans-serif;
}

.publish-window .label {
  margin: 10px auto auto 20px;
  font-weight: 500;
}

.publish-window .heading {
  font-size: 35px;
  margin: 20px auto auto 20px;
}

.publish-window .close-icon {
  position: absolute;
  color: #2F3033;
  font-size: 18px;
  top: 10px;
  right: 10px;
}

.publish-window .thumbnail-preview {
  float: left;
  width: 250px;
  border-radius: 5px;
  margin: 5px auto auto 20px;
}

.publish-window .thumbnail-container, .publish-window .heading-container {
  width: 100%;
  height: 40px;
}

.publish-window .heading-container input {
  border: none;
  font-size: 15px;
  margin: 10px auto auto 10px;
  width: 80%;
}

.publish-window .heading-container input:focus {
  outline: none;
}

.publish-window .uploadImg {
  float: left;
  margin: 5px auto auto 20px;
  width: 120px;
  height: 30px;
  background: #00C06E;
  color: #FAFAFA;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.publish-window .publish-pl-window-btn {
  width: 60%;
  height: 35px;
  margin: 10px auto auto 20px;
  border-radius: 15px;
  border: none;
  background: black;
  color: white;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}

.tag-container {
  width: 100%;
  height: 50px;
}

.tag-container {
  height: 30px;
  max-width: 100%;
  float: left;
  margin: 10px auto auto 20px;
  font-weight: 500;
}

.tag-container .tags-label {
  float: left;
  margin-top: 5px;
  font-size: 17px;
}

.tag-container input {
  width: auto;
  margin: 7px auto auto 10px;
  border: none;
  border-radius: 10px;
  float: left;
}

.tag-container input:focus, .tag-container input:valid {
  outline: none;
}

.tag-container .tag {
  margin: 3px auto auto 10px;
  padding: 0 10px 3px 10px;
  border: none;
  border-radius: 30px;
  background-color: #00C06E;
  color: #FFFFFF;
  z-index: 5;
  float: left;
  cursor: pointer;
}

.tag-container .tag p {
  margin: 4px auto auto 4px;
  float: left;
  font-size: 14px;
}

.tag-container .tag .delete-tag {
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 1000;
  margin-left: 4px;
}

.darken {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.darken-half {
  -webkit-filter: brightness(75%);
          filter: brightness(75%);
}

@media only screen and (max-width: 750px) {
  .heading {
    width: 100%;
  }
}

.small {
  width: 50%;
  height: 200px;
}

.banner {
  width: 100%;
  height: 200px;
}

.large {
  width: 100%;
  height: 500px;
}

.adbtn {
  border-radius: 8px;
  background-color: #f44336;
  border: 0px solid black;
  margin: 2px;
}

.adbtn:focus {
  border: 0px solid black;
  outline: 0;
}

.adbtn:hover {
  opacity: 0.8;
}
/*# sourceMappingURL=write.css.map */
:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

.crop-container {
  height: 100%;
  width: 100%;
  margin-top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.crop-container .img-container {
  position: relative;
  width: 60%;
  height: 60%;
  background-color: white;
}

.crop-container .img-container .reactEasyCrop_Container {
  position: relative;
  height: 100%;
  width: 100%;
}

.crop-container .Slider {
  z-index: 8;
}

.crop-container .Slider input {
  z-index: 9;
}

.form-group {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 3;
  background-color: #FAFAFA;
  border-radius: 10px;
}

.header {
  height: 32px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.header .close-icon {
  margin: 10px auto auto 10px;
  color: #656565;
}

.DragNDrop-Container {
  height: 270px;
  position: relative;
  font-family: 'roboto', sans-serif;
}

.DragNDrop-Container .dropzone {
  height: 300px;
  text-align: center;
  cursor: pointer;
}

.crop-container {
  width: 1000px;
  height: 600px;
  display: block;
  position: absolute;
}

.img-slider {
  -webkit-appearance: none;
  height: 8px;
  width: 500px;
  position: relative;
  margin: 20px auto auto 50px;
  background: #d3d3d3;
  opacity: 0.8;
  border-radius: 10px;
  transition: opacity .2s;
}

.img-slider:hover, .img-slider:active {
  opacity: 1;
}

.img-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
          appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #00C06E;
  cursor: pointer;
}

.confirm-btn-div .confirm-btn {
  width: 250px;
  height: 40px;
  border: none;
  background-color: #00C06E;
  cursor: pointer;
  border-radius: 20px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 800;
  margin: 10px auto auto 175px;
}
/*# sourceMappingURL=dropzone-cropper.css.map */
:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

.register-side, .login-side {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
}

.register-form {
  font-family: 'roboto', sans-serif;
  background: linear-gradient(120deg, #00C051, #D3C94D);
  height: 100%;
  width: 100%;
}

.darken {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.lighten {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.container-center {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  background-color: #FAFAFA;
  border-radius: 10px;
}

.container-center h1 {
  margin: 0 auto 0 auto;
}

.container-center form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container-center .forgot-password {
  color: grey;
}

.container-center .direct-link {
  text-decoration: none;
  color: #00C06E;
}

.container-center .direct-link:hover {
  text-decoration: underline;
}

.container-center .caret-left {
  font-size: 40px;
  left: 20px;
  top: 10px;
  position: absolute;
  color: black;
}

.wrire-logo {
  width: 40px;
  margin-top: 20px;
}

.text-first-obj {
  margin-top: 25px;
}

.text-field {
  width: 80%;
  height: 48px;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.text-field input {
  width: 94%;
  height: 40px;
  margin-top: 8px;
  font-size: 16px;
  padding: 0 5px;
  background: none;
  border: none;
}

.text-field input:focus, .text-field input:valid {
  border: none;
  outline: none;
}

.text-field label {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none;
  transition: .2s;
  font-weight: 500;
  color: #656565;
}

.text-field .input-group:focus ~ label, .text-field .input-group:valid ~ label {
  color: #00C06E;
  font-size: 12px;
  top: 10px;
}

.text-info-bottom {
  margin-bottom: 10px;
}

.information-bottom {
  color: red;
  margin: 0 auto 10px auto;
}

.profile-picture-preview {
  width: 80px;
  height: 80px;
  margin: 10px auto 15px auto;
  position: relative;
}

.profile-picture-preview img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.profile-picture-preview a {
  width: 40px;
  height: 40px;
}

.profile-picture-preview a:focus ~ .camera-icon {
  background-color: white;
}

.profile-picture-preview .camera-icon {
  font-size: 25px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
}

.text-bio {
  height: 100px;
}

.text-bio .textarea-bio {
  width: 94%;
  height: 75px;
  resize: none;
  margin-top: 15px;
  font-family: 'roboto', sans-serif;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
}

.text-bio .textarea-bio:focus ~ .label-bio, .text-bio .textarea-bio:valid ~ .label-bio {
  color: #00C06E;
  font-size: 12px;
  top: 10px;
}

.text-bio .label-bio {
  top: 25px;
}

.submitButton {
  width: 80%;
  height: 45px;
  background-color: #00C06E;
  border: 1px solid #FAFAFA;
  border-radius: 25px;
  font-weight: 800;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
}

.submitButton:hover {
  border-color: #00C06E;
}

.bigger-window {
  width: 600px;
  height: 500px;
}

.confirm-Email {
  height: 200px;
}
/*# sourceMappingURL=register-login.css.map */
:root {
  --article-height: 145px;
  --thumbnail-width: var(--article-height) * 1.6667;
}

.user-info-container-container {
  border-bottom: 1px solid #e0e0e0;
}

.user-info-container {
  margin-top: 0;
  margin: auto;
  display: block;
  position: relative;
}

.user-info-container .user-standard-info-container {
  height: 100px;
  margin-top: 30px;
}

.user-info-container .user-standard-info-container .profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 60px;
  float: left;
}

.user-info-container .user-standard-info-container .name-following-info {
  width: calc(100% -  250px);
  margin: auto 0 auto 20px;
  float: left;
}

.user-info-container .user-standard-info-container .name-following-info .username-name {
  font-weight: 700;
  font-size: 26px;
  width: 100%;
  margin: 20px auto 0 auto;
  display: inline-block;
}

.user-info-container .user-standard-info-container .name-following-info #follower {
  margin-top: 10px;
  float: left;
}

.user-info-container .user-standard-info-container .name-following-info #following {
  margin: 10px 0 auto 120px;
}

.user-info-container .user-standard-info-container .follow-btn {
  background-color: black;
  color: white;
  border: 1px solid #FAFAFA;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 700;
  float: right;
  margin-top: 48px;
}

.user-info-container .user-standard-info-container .follow-btn:hover {
  border: black;
}

.user-info-container .bio-links-container {
  margin-top: 0px;
}

.user-info-container .bio-links-container .bio {
  margin-top: 10px;
}

.user-info-container .bio-links-container .placeholder {
  height: 5px;
}
/*# sourceMappingURL=user.css.map */
